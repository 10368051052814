import { captureRemixErrorBoundaryError } from "@sentry/remix";
import { LinksFunction, LoaderFunctionArgs, json } from "@netlify/remix-runtime";
import { Links, Meta, Outlet, Scripts, ScrollRestoration, useRouteError, useRouteLoaderData } from "@remix-run/react";
import { Header } from "~/components/layout/header";
import { Heading } from "~/components/heading";
import { EmailSignup } from "~/components/email-signup";
import { Footer } from "~/components/layout/footer";
import styles from "~/globals.css?url";
import { getNavMenus, getOptions } from "./lib/cms/cms";
import { env } from "./lib/env";
import { ClientOnly } from "remix-utils/client-only";
import { ErrorBoundaryy } from "~/templates/error-boundary";
import ClarityScript from "./components/analytics/clarity";
import { GoogleAnalytics } from "./components/analytics/google-analytics";
import { isCalendarSpamBot } from "./lib/is-calendar-spam-bot";

export const links: LinksFunction = () => {
  return [
    { rel: "stylesheet", href: styles },
    { rel: "stylesheet", href: "https://use.typekit.net/fob5pnp.css" },
  ];
};

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const requestLogData = { url: request.url, userAgent: request.headers.get("user-agent") };

  const isSpam = isCalendarSpamBot(request);
  if (isSpam) {
    console.log({ ...requestLogData, status: 403 });
    throw new Response("Not Found", { status: 404 });
  }

  const headerNav = await getNavMenus();
  const options = await getOptions();

  console.log({ ...requestLogData, status: 200 });

  return json({
    appEnv: env("APP_ENV") as string | null,
    headerNav,
    options,
  });
};

export function Layout({ children }) {
  const data = useRouteLoaderData<typeof loader>("root");

  if (!data) {
    return null;
  }

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        {data?.appEnv === "production" && (
          <>
            <GoogleAnalytics />
            <ClarityScript />
          </>
        )}
      </head>
      <body>
        {data && <Header navigation={data.headerNav} socialLinks={data.options.social_media} />}
        {children}
        <section className="relative py-10">
          <div className="absolute top-0 left-0 w-full h-full bg-foreground opacity-5 pointer-events-none"></div>
          <div className="max-w-2xl mx-auto px-6">
            <Heading asChild>
              <h2>Stay Connected</h2>
            </Heading>
            <p className="mt-6 text-lg">Get the latest news and event information straight to your inbox.</p>
            <div className="mt-6 -mx-5">
              <ClientOnly>{() => <EmailSignup />}</ClientOnly>
            </div>
          </div>
        </section>
        {data && <Footer socialLinks={data.options.social_media} />}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return ErrorBoundaryy;
};

export default function App() {
  return <Outlet />;
}
